import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://6c45f4977aac7d3febc6cc7e59dc2f02@o4508018670698496.ingest.de.sentry.io/4508018672205904",
  debug: false,
  environment: "development",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});